// @ts-ignore
import React, { useEffect, useState } from "react";
// import * as XLSX from 'xlsx'
import 'flowbite';

import { Modal } from 'flowbite'
import type { ModalOptions, ModalInterface } from 'flowbite'


namespace XLSX {
  export type WorkBook = {
    SheetNames: string[],
    Sheets: any


  }

  function read() {

  }
}

import { Building, Company, GlobalRepository, User } from "./Data";
import { CarouselItem } from './MyCarousel';
import { Carousel } from 'react-responsive-carousel';

enum Screen {
  Login,
  FileUploader,
  MaterialConnector,
  Company,
  NewBuilding,
  Building,
  DashboardCompare,
  TradeSellPage,
  ProfileMainPage,
  Admin,
}


import './App.css';
import { FlowbiteCarousel } from "./FlowbiteCarousel";

function parse(fileXlsx: XLSX.WorkBook) {
  // Sheet 1 rows
  // 1: A             B                  C               D                       E                         F             G               H
  //Element Area	Element Category	Element Family	Element Structural Usage	Element STRUCTURAL LEVEL	Element Type	Element Volume	Item Material	Item Type
  // 0	Generic Models	3D Joint - Line Based			Construction Joint - CJ	0		Generic Models: 3D Joint - Line Based: Construction Joint - CJ

  // Sheet 2 columns
  // A3
  // 
  if (fileXlsx == null)
    return 'null';
  return fileXlsx.SheetNames
}

function readFileAsync(file) {

  return new Promise<XLSX.WorkBook>((r) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target?.result;
      if (data) {
        let fileXlsx = XLSX.read(data, {
          type: 'binary'
        });
        r(fileXlsx);
      }
    }
    reader.readAsBinaryString(file as File);
  })
}

function Login({ companies, onLogin }) {
  function handleLogin(c) {
    onLogin(c);
  }

  console.log('c', companies)

  return (
    <div>
      Select company & Login:
      {!companies && 'no companies'}
      {companies && companies.map(c =>
        <button key={c.id} onClick={() => handleLogin(c)}>{c.name}</button>
      )
      }
    </div>
  )
}

function FileUploader({ onFileUploaded }) {
  const [file, setFile] = useState<File | null>(null);

  const handleFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFile(e.target.files?.[0] || null);
  };

  async function handleUpload(e) {
    if (file == null) {
      onFileUploaded(null);
      return;
    }

    const xlsx = await readFileAsync(file);
    console.log('xlsx', xlsx)
    onFileUploaded(xlsx);
  }

  return (
    <div>
      <input type="file" onChange={handleFile} />
      <button onClick={handleUpload}>upload</button>
      {<p>{file?.name || '.'}</p>}
    </div>
  );
}

function MaterialConnector({ file, onConnected }) {

  const [parsed, setParsed] = useState<any>(null);

  useEffect(() => {
    const parsed = parse(file);
    setParsed(parsed);
    onConnected(parsed);
  }, [file])

  return (<p>Material Connector
    <br />
    {parsed}
  </p>)
}

function ProfileMainPage() {
  return (<p>ProfileMainPage</p>)
}

interface ProjectListProps {
  projects: Company[];
}

const ProjectList: React.FC<ProjectListProps> = ({ projects }) => (
  <ul>
    {projects.map((project) => (
      <li key={project.id}>{project.name}</li>
    ))}
  </ul>
);

function CompanyView({ company, onNewBuilding, onSelectBuilding }) {

  function handleNewBuilding() {
    onNewBuilding();
  }

  function handleSelectBuilding(b) {
    onSelectBuilding(b)
  }

  if (!company) {
    return null;
  }

  return (
    <div>
      <h2>{company.name}</h2>
      {company.buildings && company.buildings.map(b =>
        <button key={b.id} onClick={() => handleSelectBuilding(b)}>{b.name}</button>
      )
      }
      <button onClick={handleNewBuilding}>New Building</button>

    </div>
  )
}

type ParsedXlsx = any[]
function xlsxParser(xlsx): ParsedXlsx {
  let x = xlsx.Sheets["Sheet 1"];
  let rows = []
  for (let i = 3; i < 10; i++) {
    rows.push({
      id: i,
      description: x["A" + i].v,
      unit: x["B" + i].v,
      quantity: x["C" + i].v,
      material: x["D" + i].v,
      ec3: x["E" + i].v,
    })
  }

  console.log(rows)
  return rows;
}

function NewBuildingView({ onSave }) {
  const [name, setName] = useState<string>('');
  const [xlsx, setXlsx] = useState();
  const [items, setItems] = useState<ParsedXlsx>();
  const [truckDistance, setTruckDistance] = useState<number[]>();

  function handleFileUploaded(d) {
    setXlsx(d);
    let p = xlsxParser(d)
    setItems(p)
    let td = [];
    for (let i of p) {
      td[i.id] = 0
    }
    setTruckDistance(td);
    console.log(p, td)
  }

  function handleSave() {
    onSave({
      name: name,
      items: items
    })
  }

  return (
    <div>
      Name: <input value={name} onChange={e => setName(e.target.value)} />
      <FileUploader onFileUploaded={handleFileUploaded} />
      {
        items && items.map(i =>
          <div>
            {i.description} {i.quantity}
            <input value={truckDistance[i.id]} onChange={e => { truckDistance[i.id] = parseInt(e.target.value) }} />
          </div>
        )
      }
      <button onClick={handleSave}>save</button>
    </div>
  )
}



function BuildingView({ building }) {

  if (!building) return null;

  return (
    <div>
      <h2>{building.name}</h2>
      <h3>Items</h3>
      <ul>
        {building.items && building.items.map((item) => (
          <li key={item.id}>
            {item.description} - {item.quantity}
          </li>
        ))}
      </ul>
      <h3>Final Offset Value: {building.finalOffsetValue}</h3>
    </div>
  )
}

function DashboardCompare() {
  return (<p>DashboardCompare</p>)
}

const carouselsData: CarouselItem[][] = [
  // #1
  [
    new CarouselItem('Material1', 'long description', '$30/mu', 'images/1.jpeg'),
    new CarouselItem('Material2', 'this one is good', '$20.50/mu', 'images/2.jpeg'),
    new CarouselItem('Material3', 'not good', '$35/mu', 'images/3.jpeg'),
    new CarouselItem('Material4', 'very good', '$30/mu', 'images/4.jpeg'),
    new CarouselItem('Material5', 'ok not bad', '$30/mu', 'images/1.jpeg'),
    new CarouselItem('Material6', 'more info about it', '$30/mu', 'images/2.jpeg'),
    new CarouselItem('Material7', 'some stuff', '$30/mu', 'images/1.jpeg'),
    new CarouselItem('Material8', 'ok more stuff', '$30/mu', 'images/2.jpeg'),
  ],
  // #2
  [
    new CarouselItem('C2title21', 'd2', '$3', 'images/3.JPG'),
    new CarouselItem('C2title22', 'd2', '$5', 'images/4.jpeg'),
    new CarouselItem('C2title23', 'd2', '$5', 'images/5.jpeg'),
    new CarouselItem('C2title21', 'd2', '$3', 'images/3.JPG'),
    new CarouselItem('C2title22', 'd2', '$5', 'images/4.jpeg'),
    new CarouselItem('C2title23', 'd2', '$5', 'images/5.jpeg'),
    new CarouselItem('C2title21', 'd2', '$3', 'images/3.JPG'),
    new CarouselItem('C2title22', 'd2', '$5', 'images/4.jpeg'),
    new CarouselItem('C2title23', 'd2', '$5', 'images/5.jpeg'),
  ],
  // #3
  [
    new CarouselItem('C3titleA', 'd2', '$100/m', 'images/6.jpeg'),
    new CarouselItem('C3titleB', 'd2', '$60/m', 'images/7.png'),
  ]
];

function clamp(v, lo, hi) {
  if (v < lo) return lo;
  if (v > hi) return hi;
  return v;
}

function TradeSellPage() {
  const SV_MAX = 1000;
  const [activeCarousel, setActiveCarousel] = useState(0);
  const [scrollValue, setScrollValue] = useState(0);

  const handleScroll = (e: React.WheelEvent) => {

    setScrollValue(clamp(scrollValue + e.deltaY, -SV_MAX, SV_MAX))
    setActiveCarousel(Math.floor(scrollValue / 1000) + 1);
    // if (e.deltaY < 0) {
    //   setActiveCarousel((prevActiveCarousel) => Math.max(prevActiveCarousel - 1, 0));
    // } else {
    //   setActiveCarousel((prevActiveCarousel) => Math.min(prevActiveCarousel + 1, carouselsData.length - 1));
    // }
  };


  return (
    <div onWheel={handleScroll}>

      <div>
        <div>
          <h2>Top</h2>
          {/*<Carousel dynamicHeight={true} showIndicators={false} showThumbs={false} width={300}>*/}
          {/*    {carouselsData && carouselsData[0].map((i: CarouselItem, index) =>*/}
          {/*    <div key={index} style={{position: 'relative', textAlign: 'center', maxWidth: '300px'}}>*/}
          {/*      <b style={{*/}
          {/*          position: 'absolute',*/}
          {/*          top: '0',*/}
          {/*          left: '50%',*/}
          {/*          transform: 'translateX(-50%)',*/}
          {/*          backgroundColor: 'white', // White background for title*/}
          {/*          padding: '5px' // Padding around the title text*/}
          {/*        }}>*/}
          {/*        {i.title}*/}
          {/*      </b>*/}
          {/*      <img src={i.image} style={{maxHeight: '300px', width: '100%', objectFit: 'cover'}}/>*/}
          {/*      <p style={{*/}
          {/*          position: 'absolute',*/}
          {/*          bottom: '0',*/}
          {/*          left: '0',*/}
          {/*          margin: '0',*/}
          {/*          backgroundColor: 'white',*/}
          {/*          borderRadius: '10px', // Rounded corners for description*/}
          {/*          padding: '5px' // Padding around the description text*/}
          {/*        }}>*/}
          {/*        {i.description}*/}
          {/*      </p>*/}
          {/*      <div style={{*/}
          {/*          position: 'absolute',*/}
          {/*          bottom: '0',*/}
          {/*          right: '0',*/}
          {/*          backgroundColor: 'white', // White background for price*/}
          {/*          borderRadius: '10px', // Rounded corners for price*/}
          {/*          padding: '5px' // Padding around the price text*/}
          {/*        }}>*/}
          {/*        {i.price}*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*    )}*/}
          {/*  </Carousel>*/}
        </div>
        <h2>active {activeCarousel / 1}</h2>
        {carouselsData.map((carouselItems, index) => (
          <div style={
            activeCarousel / 1 == index
              ? { height: 450 }
              : { height: 300 }} key={index}>

            <h3>Carousel {index} {activeCarousel == index ? 'ACTIVE' : ''}</h3>

            <div style={{ border: '3px solid red' }}>
              <Carousel dynamicHeight={true} showIndicators={false} showThumbs={false} width={300}>
                {carouselItems && carouselItems.map((i: CarouselItem, index) =>
                  <div key={index} style={{ position: 'relative', textAlign: 'center', maxWidth: '300px' }}>
                    <b style={{
                      position: 'absolute',
                      top: '0',
                      left: '50%',
                      transform: 'translateX(-50%)',
                      backgroundColor: 'white', // White background for title
                      padding: '5px' // Padding around the title text
                    }}>
                      {i.title}
                    </b>
                    <img src={i.image} style={{ maxHeight: '300px', width: '100%', objectFit: 'cover' }} />
                    <p style={{
                      position: 'absolute',
                      bottom: '0',
                      left: '0',
                      margin: '0',
                      backgroundColor: 'white',
                      borderRadius: '10px', // Rounded corners for description
                      padding: '5px' // Padding around the description text
                    }}>
                      {i.description}
                    </p>
                    <div style={{
                      position: 'absolute',
                      bottom: '0',
                      right: '0',
                      backgroundColor: 'white', // White background for price
                      borderRadius: '10px', // Rounded corners for price
                      padding: '5px' // Padding around the price text
                    }}>
                      {i.price}
                    </div>
                  </div>
                )}
              </Carousel>
            </div>
          </div>
        ))}
      </div>
    </div>
  );

}

interface Repository {
  companies: Company[]
}

/* DATA STUFF ****/
const SAVE_KEY = 'GLOBAL_REPOSITORY';
const r = localStorage.getItem(SAVE_KEY);
let repository = r != undefined
  ? JSON.parse(r)
  : {
    companies: []
  };
console.log('loaded', repository)

function handleSave() {
  localStorage.setItem(SAVE_KEY, JSON.stringify(repository));
  console.log('saved', repository);
}
/* DATA STUFF *****/

function AdminPage(
  //{ repository }: { repository: GlobalRepository }
) {

  function handleClear() {
    repository = {
      companies: []
    }
  }
  function handleDoAdmin() {
    repository.companies.push({
      id: 0,
      name: 'BigCo',
      buildings: []
    })
  }
  return (
    <div>
      <button onClick={handleClear}>Clear</button>
      <button onClick={handleDoAdmin}>Add BigCo</button>
    </div>
  )
}

export const App: React.FC = () => {
  const [currentScreen, setCurrentScreen] = useState<Screen>(Screen.Login);
  const [companies, setCompanies] = useState<Company[]>();
  const [currentCompany, setCurrentCompany] = useState<Company>();
  const [building, setBuilding] = useState<Building>();

  const [file, setFile] = useState();
  const [connections, setConnections] = useState();

  const isSelected = (screen: Screen) => currentScreen === screen;

  // on startup
  useEffect(() => {
    const c = repository.companies;
    setCompanies(c);
  }, [repository])

  function handleLogin(c) {
    setCurrentCompany(c);
    setCurrentScreen(Screen.Company);
  }

  function handleFileUploaded(file) {
    setFile(file);
    setCurrentScreen(Screen.MaterialConnector);
  }

  function handleSaveNewBuilding(b) {
    currentCompany.buildings.push(b);
    console.log('saved', currentCompany)
    setCurrentScreen(Screen.Company)
  }

  function handleSelectBuilding(b) {
    setBuilding(b);
    setCurrentScreen(Screen.Building)
  }

  function handleNewBuilding() {
    setCurrentScreen(Screen.NewBuilding)
  }

  console.log('r', companies)

  return (
    <div className="App">
      <header className="App-header">Trade Carbon Credits</header>
      <nav className="App-nav">
        {Object.values(Screen).map((screen, index) =>
          typeof screen === 'number' ? (
            <button key={index} onClick={() => setCurrentScreen(screen)}>
              {Screen[screen]}
            </button>
          ) : null
        )}
        <button onClick={handleSave}>save</button>
      </nav>
      <main className="App-main">
        <div data-visible={isSelected(Screen.Login)}>
          <FlowbiteCarousel />
          <Login companies={companies} onLogin={handleLogin} />
        </div>
        <div data-visible={isSelected(Screen.FileUploader)}>
          <FileUploader onFileUploaded={handleFileUploaded} />
        </div>
        <div data-visible={isSelected(Screen.MaterialConnector)}>
          <MaterialConnector file={file} onConnected={setConnections} />
        </div>
        <div data-visible={isSelected(Screen.Company)}>
          <CompanyView company={currentCompany} onNewBuilding={handleNewBuilding} onSelectBuilding={handleSelectBuilding} />
        </div>
        <div data-visible={isSelected(Screen.NewBuilding)}>
          <NewBuildingView onSave={handleSaveNewBuilding} />
        </div>
        <div data-visible={isSelected(Screen.Building)}>
          <BuildingView building={building} />
        </div>
        <div data-visible={isSelected(Screen.DashboardCompare)}>
          <DashboardCompare />
        </div>
        <div data-visible={isSelected(Screen.TradeSellPage)}>
          <TradeSellPage />
        </div>
        <div data-visible={isSelected(Screen.ProfileMainPage)}>
          <ProfileMainPage />
        </div>
        <div data-visible={isSelected(Screen.Admin)}>
          <AdminPage
          //repository={repository}
          />
        </div>
      </main>
    </div>
  );
}

export default App;
