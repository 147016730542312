import * as React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader


export class CarouselItem {
  constructor(
      public title: string,
  public description: string,
  public price: string,
  public image: string)
  { }
}

interface CustomCarouselProps {
  items: CarouselItem[];
  active: boolean;
}

const MyCarousel = ({ items }) => {
  return
}

export default MyCarousel;
